export default {
  computed: {
    totalPages() {
      return this.$store.getters[`${this.$data.storePath}/totalPages`];
    },
    page: {
      get() {
        return this.$data.storePath === "employees"
          ? this.$store.state.employees.currentPage
          : this.$store.getters[`${this.$data.storePath}/currentPage`];
      },
      set(val) {
        return this.$data.storePath === "employees"
          ? this.$store.commit(`${this.$data.storePath}/SET_CURRENT_PAGE`, val - 1)
          : this.$store.commit(
              `${this.$data.storePath}/SET_CURRENT_PAGE`,
              val
            );
      },
    },
  },
};
