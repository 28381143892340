export default {
  methods: {
    async resetData() {
      if (this.$data.actionType !== "fetchAllowance") {
        this.query = "";
      }
      await this.initialize();
    },

    async paginatePage() {
      this.isLoading = true;
      this.scrollToTop();
      const data = {
        yearMonth: `${this.date}`,
      };
      let params;
      switch (this.$data.actionType) {
        case "fetchBankAdviceReport":
          params = { page: this.page, data };
          break;
        case "fetchCurrentNspPayroll":
          params = {
            page: this.page || 1,
            yearMonth: data.yearMonth,
            searchName: "",
          };
          break;
        case "fetchPayrollReport":
          params = { page: this.page, data: this.filterForm.date };
          break;
        case "fetchTierOneReport":
          params = { page: this.page, data: { yearMonth: this.filterForm.date } };
          break;
        default:
          params = this.page;
      }

      try {
        await this.$store.dispatch(
          `${this.$data.sourcePath}/${this.$data.actionType}`,
          params
        );
      } catch (error) {
        console.error("Error dispatching action:", error);
      } finally {
        this.isLoading = false;
      }
    },
    scrollToTop() {
      window.scroll({
        top: 0,
        left: 0,
        behavior: "smooth",
      });
    },
  },
};
