import moment from 'moment'
export default {
    methods: {
        getMonthsDifference: function (startYearMonth, endYearMonth){
            const stringStart = `${startYearMonth}-25`;
            const stringEnd = `${endYearMonth}-25`;
            const start = moment(stringStart, "YYYY-MM-D");
            const end = moment(stringEnd, "YYYY-MM-D");
            // Months
            return end.diff(start, "months", true) + 1;
        },

        formatFigure: function (item) {
            return item ? new Intl.NumberFormat('en-US' , {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2
            }).format(item) : '0.00'
          },

        monthYearFormat: function (date) {
          return moment(date).format('MMM YYYY') || ''
        },
    }
}